
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { DetailInfo } from '@/types/devicesCategory'

@Component({
  components: { UploadFile }
})
export default class ExpertAdd extends Vue {
  private info: DetailInfo = {
    deviceTypeName: '',
    synopsis: '',
    imgList: []
  }

  private rules = {
    synopsis: [
      { required: true, message: '请输入类型介绍', trigger: ['blur'] }
    ],
    imgList: [
      { required: true, message: '请上传图片', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false
  private curId = ''

  created () {
    this.curId = this.$route.params.id as string

    this.getDetail(this.curId)
  }

  deleteImg (index: number) {
    this.info.imgList.splice(index, 1)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.deviceCategory.selectDeviceTypeById, { deviceTypeId: id })
      .then((res) => {
        this.info = res || {}
      })
  }

  onSuccess (file: FileInfo) {
    console.log('file: ', file)
    this.info.imgList.push(file)
  }

  onSubmit () {
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios
          .post(this.$apis.deviceCategory.updateDeviceType, this.info)
          .then(() => {
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$router.push({ path: '/deviceCategory' })
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
